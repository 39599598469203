<template>
  <layout-popover-menu
    v-bind="$attrs"
    v-on="$listeners"
    :is-activator-button="false"
  >
    <!-- Import File Menu label -->
    <template v-slot:default>
      {{ menuTitle }}
    </template>

    <!-- Import File Popover Menu Icon -->
    <template v-slot:menuIconName> {{ menuIcon }}</template>

    <!-- Import File Popover Dialog Icon -->
    <template v-slot:popoverIconName>
      {{ menuIcon }}
    </template>

    <!-- Import File Popover Dialog Title -->
    <template v-slot:popoverTitle>
      {{ menuTitle }}
    </template>
    <!-- Close Check Box Import File Popover Menu -->
    <template #iconCrossName>
      <base-tooltip-button @click="closePopoverMenu">
        <template v-slot:icon-name>
          {{ iconClose }}
        </template>
        <template v-slot:toolTip>
          {{ closePopoverTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- Import File Popover Dialog Context -->
    <template v-slot:popoverContext>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>

      <check-in-file
        @fileInputChange="onFileInputChange"
        @checkInOptionChange="onCheckInOptionChange"
        :options="hasPages ? checkInFileOptions : []"
        :select-option="defaultCheckInFileOption"
        :progress="uploadFileProgress"
        :input-type="inputType"
        :is-signed-checkin="isSignedCheckin"
      >
      </check-in-file>

      <expansion-panels-layout :inset="true" v-show="!isDocumentViewer">
        <!-- header -->
        <template v-slot:header>
          Upload Options &emsp;
          <core-chip-group
            v-show="hasUploadOptions"
            :items="uploadOptionsList"
            :column="true"
            :chip-small="isSmallDevice"
            :show-arrows="true"
          >
          </core-chip-group>
        </template>
        <template v-slot:content>
          <check-in-option
            class="mt-4"
            :check-in-option="checkInOption"
          ></check-in-option>
        </template>
      </expansion-panels-layout>
    </template>

    <!-- Import File Actions-->
    <template v-slot:popoverActions>
      <base-button @click="onOkay" :disabled="!uploadFileInput">{{
        menuOkCommandLabel
      }}</base-button>
      <base-button @click="closePopoverMenu">Close</base-button>
    </template>
  </layout-popover-menu>
</template>

<script>
// design
import { iconClose, iconImport } from "@/design/icon/iconConst";

// mixins
import { popoverMenuMixin } from "@/mixins/shared/base/popover/popoverMenuMixin";
import { fileCheckInMixin } from "@/mixins/shared/file/fileCheckInMixin";
import { toLowerCaseSafe } from "@/utils";
import {
  isFilePdf,
  isDocumentViewerSupportingFileExtension
} from "@/model/viewer/viewerFileModel";
import { actionResultType } from "@/model/action/actionModel";
import { findImageOperation, imageOperation } from "@/model/image/imageModel";
import {
  fullRecordVersionName,
  getImportedFileExtension,
  localFileFirstOrDefaultExtension
} from "@/services/record/recordService";
import { createCheckInOption } from "@/model/record/checkInModel";
import { extensionName } from "@/model/record/fileModel";

export default {
  name: "ImportFilePopoverMenu",
  inheritAttrs: false,
  mixins: [popoverMenuMixin, fileCheckInMixin],
  components: {
    LayoutPopoverMenu: () =>
      import("@/components/shared/core/layout/menu/LayoutPopoverMenu"),
    BaseButton: () => import("@/components/shared/base/BaseButton"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    CheckInFile: () => import("@/components/file/CheckInFile"),
    CheckInOption: () =>
      import("@/components/shared/core/checkIn/CheckInOption"),
    ExpansionPanelsLayout: () =>
      import("@/components/shared/core/expansionPanel/ExpansionPanelsLayout"),
    CoreChipGroup: () =>
      import("@/components/shared/core/chipGroup/CoreChipGroup")
  },
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]} | undefined | null}
     */
    currentRecord: undefined,
    hasPages: {
      type: Boolean,
      default: true
    },
    progress: {
      type: Number,
      required: true
    },
    importEvent: {
      type: Function,
      required: true
    },
    isDocumentViewer: {
      type: Boolean,
      required: true
    },
    isSignedCheckin: {
      type: Boolean,
      default: false
    },
    isDocumentOpened: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconClose: iconClose,
      iconImport: iconImport,
      //save latest user selected file option
      currentSelectedOption: undefined,
      checkInOption: createCheckInOption()
    };
  },
  computed: {
    isViewer() {
      return this.isDocumentViewer;
    },
    record() {
      return this.currentRecord ?? undefined;
    },

    /**
     * Import Operation
     * @return {{name: string, icon: string, label: string}} Import Operation
     */
    operation() {
      return findImageOperation(imageOperation.Import);
    },

    /**
     * Import operation Label
     * @return {string} Import operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    menuIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Import Page Dialog title
     * @return {string} Import Page Dialog title
     */
    menuTitle() {
      return this.isDocumentViewer
        ? `${this.operationLabel}`
        : this.isSignedCheckin
        ? "CheckIn Signed File"
        : "Upload File";
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    menuOkCommandLabel() {
      return this.isDocumentViewer ? this.operation?.name : "Upload";
    },

    closePopoverTooltip() {
      return `${this.closePopoverLabel} ${this.menuTitle}`;
    },

    /**
     * Menu action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${
        this.record?.recordType
      }: '${fullRecordVersionName(this.record)}'`;
    },

    uploadFileProgress() {
      return this.progress;
    },

    /**
     * Verify if screen size is small
     * @return {boolean}
     */
    isSmallDevice() {
      return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly;
    },

    /**
     * Get Record / Wip Extension
     * @return {*|string}
     */
    recordExtension() {
      return (
        localFileFirstOrDefaultExtension(this.record) ??
        this.record?.extension ??
        ""
      );
    },

    /**
     * Get Available Input Types
     * @return {string}
     */
    inputType() {
      return this.isViewer && !isFilePdf(this.recordExtension)
        ? "image/*"
        : "*";
    }
  },
  methods: {
    /**
     * On File Input Change Event handler
     * @param file
     */
    onFileInputChange(file) {
      try {
        if (!this.isViewer) {
          this.uploadFileInput = file;
        } else {
          this.uploadFileInput = null;
          const ext = toLowerCaseSafe(getImportedFileExtension(file));
          console.log(ext);
          if (!ext) {
            this.clearAlert();
          } else if (!isDocumentViewerSupportingFileExtension(ext)) {
            this.internalAlert = this.createAlertWarning(
              this.formatAlertError(
                this.action,
                "File Not Supported. Please Select Image Files only"
              ),
              false
            );
          } else {
            if (!isFilePdf(this.recordExtension) && ext === extensionName.pdf) {
              this.internalAlert = this.createAlertWarning(
                this.formatAlertError(
                  this.action,
                  "Please Select Image Files only"
                ),
                false
              );
            } else {
              this.uploadFileInput = file;
              this.clearAlert();
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    async onOkay() {
      try {
        console.log("this.checkInFileOption", this.checkInFileOption);
        this.currentSelectedOption = this.checkInFileOption;
        this.clearAlert();
        const result = await this.importEvent(
          this.uploadFileInput,
          this.isInsertFile,
          this.isInsertFileAtBeginning,
          this.checkInOption
        );
        if (result?.type === actionResultType.success) {
          await this.autoCloseMenu(result.message);
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.uploadFileInput = null;
        //sets default file option to previously selected option
        this.defaultCheckInFileOption = this.currentSelectedOption;
      }
    }
  },

  watch: {
    checkInOption: {
      handler() {
        this.updateSelectedOptionList();
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
